var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":_vm.name}},[_c('validation-observer',{ref:"CVDEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.editCVD($event)}}},[_c('b-form-group',{attrs:{"label":"Origin Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"VM Name","label-for":"vm_name"}},[_c('validation-provider',{attrs:{"name":"vm_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vm_name","state":errors.length > 0 ? false : null,"name":"vm_name"},model:{value:(_vm.vm_name),callback:function ($$v) {_vm.vm_name=$$v},expression:"vm_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"CVSS Score","label-for":"cvss_score"}},[_c('validation-provider',{attrs:{"name":"cvss_score","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cvss_score","state":errors.length > 0 ? false : null,"name":"cvss_score"},model:{value:(_vm.cvss_score),callback:function ($$v) {_vm.cvss_score=$$v},expression:"cvss_score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"OWASP Category","label-for":"owasp_category"}},[_c('validation-provider',{attrs:{"name":"owasp_category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"owasp_category","state":errors.length > 0 ? false : null,"name":"owasp_category"},model:{value:(_vm.owasp_category),callback:function ($$v) {_vm.owasp_category=$$v},expression:"owasp_category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Severity","label-for":"Severity"}},[_c('validation-provider',{attrs:{"name":"severity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.severity_options,"state":errors.length > 0 ? false : null},model:{value:(_vm.severity),callback:function ($$v) {_vm.severity=$$v},expression:"severity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Synopsis","label-for":"synopsis"}},[_c('validation-provider',{attrs:{"name":"synopsis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"synopsis","state":errors.length > 0 ? false : null,"name":"synopsis"},model:{value:(_vm.synopsis),callback:function ($$v) {_vm.synopsis=$$v},expression:"synopsis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Recommendation","label-for":"recommendation"}},[_c('validation-provider',{attrs:{"name":"recommendation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"recommendation","state":errors.length > 0 ? false : null,"name":"recommendation"},model:{value:(_vm.recommendation),callback:function ($$v) {_vm.recommendation=$$v},expression:"recommendation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"References","label-for":"references"}},[_c('b-form-textarea',{attrs:{"id":"references","name":"references"},model:{value:(_vm.references),callback:function ($$v) {_vm.references=$$v},expression:"references"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Update ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }