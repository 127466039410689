<template >
  <div>
    <b-card-code :title="name">
      <!-- form -->
      <validation-observer ref="CVDEditForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="editCVD">
          <!-- Name -->
          <b-form-group label="Origin Name" label-for="name">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                name="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="VM Name" label-for="vm_name">
            <validation-provider
              #default="{ errors }"
              name="vm_name"
              rules="required"
            >
              <b-form-input
                id="vm_name"
                v-model="vm_name"
                :state="errors.length > 0 ? false : null"
                name="vm_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="CVSS Score" label-for="cvss_score">
            <validation-provider
              #default="{ errors }"
              name="cvss_score"
              rules="required"
            >
              <b-form-input
                id="cvss_score"
                v-model="cvss_score"
                :state="errors.length > 0 ? false : null"
                name="cvss_score"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="OWASP Category" label-for="owasp_category">
            <validation-provider
              #default="{ errors }"
              name="owasp_category"
              rules="required"
            >
              <b-form-input
                id="owasp_category"
                v-model="owasp_category"
                :state="errors.length > 0 ? false : null"
                name="owasp_category"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Severity" label-for="Severity">
            <validation-provider
              #default="{ errors }"
              name="severity"
              rules="required"
            >
              <b-form-select
                v-model="severity"
                :options="severity_options"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Description" label-for="description">
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                :state="errors.length > 0 ? false : null"
                name="description"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Synopsis" label-for="synopsis">
            <validation-provider
              #default="{ errors }"
              name="synopsis"
              rules="required"
            >
              <b-form-textarea
                id="synopsis"
                v-model="synopsis"
                :state="errors.length > 0 ? false : null"
                name="synopsis"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Recommendation" label-for="recommendation">
            <validation-provider
              #default="{ errors }"
              name="recommendation"
              rules="required"
            >
              <b-form-textarea
                id="recommendation"
                v-model="recommendation"
                :state="errors.length > 0 ? false : null"
                name="recommendation"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="References" label-for="references">
            <b-form-textarea
              id="references"
              v-model="references"
              name="references"
            />
          </b-form-group>
          <b-button type="submit" variant="primary" :disabled="invalid">
            Update
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormSelect,
  },
  data: function () {
    return {
      name: "",
      vm_name: "",
      synopsis: "",
      description: "",
      cvss_score: "",
      owasp_category: "----",
      recommendation: "----",
      references: "----",
      severity: "",
      severity_options: [
        { value: "critical", text: "Critical" },
        { value: "high", text: "High" },
        { value: "medium", text: "Medium" },
        { value: "low", text: "Low" },
        { value: "info", text: "Info" },
      ],
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/master_vulns/" +
          this.$route.params.id, //+'/vuln-details/',
      };
      var self = this;
      this.$http(o_options).then((res) => {
        let data = res.data;
        self.name = data.name;
        self.vm_name = data.vm_name;
        self.description = data.description;
        self.synopsis = data.synopsis;
        self.recommendation = data.recommendation;
        self.cvss_score = data.cvss_score;
        self.owasp_category = data.owasp_category;
        self.severity = data.severity;
      });
    },
    editCVD: function () {
      this.$refs.CVDEditForm.validate().then((success) => {
        if (success) {
          let data = {
            name: this.name,
            vm_name: this.vm_name,
            description: this.description,
            synopsis: this.synopsis,
            recommendation: this.recommendation,
            cvss_score: this.cvss_score,
            owasp_category: this.owasp_category,
            severity: this.severity,
          };
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/master_vulns/" +
              this.$route.params.id +
              "/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.id) {
              this.$router.push({ name: "cvd" });
            }
          });
        }
      });
    },
  },
};
</script>